import dynamic from 'next/dynamic'

const SyncImage = ({ ...attrs }) => {
  return <img {...attrs} />
}

const AsyncImage = dynamic(
  () => Promise.resolve(SyncImage),
  {
    ssr: false
  }
)

const Image = AsyncImage

export default Image
