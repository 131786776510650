import PropTypes from 'prop-types'
import { Parser as HTML2ReactParser } from 'html-to-react'

const RawHTML = ({ html }) => {
  const HTMLParser = new HTML2ReactParser()

  return <>{HTMLParser.parse(html)}</>
}

RawHTML.propTypes = {
  html: PropTypes.string
}

export default RawHTML
